import React, { useEffect, useState } from "react";
import "./SingleProperty.css";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";
import "swiper/css/bundle";
import { Row, Col, Container } from "react-bootstrap";
import {
  FaBed,
  FaEuroSign,
  FaSearchengin,
  FaCity,
  FaBath,
  FaInfoCircle,
} from "react-icons/fa";
import { HiSquare2Stack } from "react-icons/hi2";
import { SiGooglemaps } from "react-icons/si";
import { IoKeySharp, IoDocument } from "react-icons/io5";
import Footer from "../Footer/Footer";
import Navbar2 from "../Navbar/Navbar2";
import unknown from "../../images/unknown.jpg";
import emailjs from "@emailjs/browser";
import WhatsAppShareButton from "../Share/WhatsAppShareButton.js";
import FacebookShareButton from "../Share/FacebookShareButton.js";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Card from "../BestProperty/Card.js";
import Whatsapp from "../Whatsapp/Whatsapp.js";

const SingleProperty = () => {
  const propertyUrl = window.location.href;
  const text = `Check out this property`;
  SwiperCore.use([Navigation]);
  const params = useParams();
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [user, setUser] = useState([]);
  const [agentData, setAgentData] = useState({});
  const [firstValidUrl,setFirstValidUrl] = useState("");
  const [mailerState, setMailerState] = useState({
    name: "",
    email: "",
    message: "",
  });
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (listing && user.length > 0) {
      const filteredData = user.find((item) => item.username === listing.agent);
      setAgentData(filteredData || {});
    }
  }, [listing, user]);

  useEffect(() => {
    const fetchListing = async () => {
      try {
        setLoading(true);
        const res = await fetch(
          `${process.env.REACT_APP_BASE_URL}/listing/get/${params.listingid}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await res.json();
        if (data.success === false) {
          setError(true);
          setLoading(false);
          return;
        }
        setListing(data);
        setLoading(false);
        setError(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    };
    const fetchUsers = async () => {
      const res = await fetch(process.env.REACT_APP_BASE_URL+"/user/allUsersWithoutVerification", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();

      if (data.success === false) {
        return;
      }

      setUser(data);
    };
    fetchListing();
    fetchUsers();
  }, [params.listingId]);

  const [formData, setFormData] = useState([]);

  useEffect(() => {
    const fetchListing = async () => {
      const res = await fetch(process.env.REACT_APP_BASE_URL+"/user/alllistings", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      if (data.success === false) {
        console.log(data.message);
        return;
      }
      setFormData(data);
    };

    fetchListing();
  }, []);

  useEffect(() => {
    if (agentData && Array.isArray(agentData.agentUrls)) {
      const validUrl = agentData.agentUrls.find(url => url);
      if (validUrl) {
        setFirstValidUrl(validUrl);
      } else {
        setFirstValidUrl(""); // Make sure to reset the state if no valid URL is found
      }
    }
  }, [agentData]); // Only run when agentData changes

  function handleStateChange(e) {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  const submitEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_9sb0llz",
      "template_ad32do8",
      e.target,
      "ZmYGGwsO3XtD3pXT4"
    );

    setMailerState({
      email: "",
      name: "",
      message: "",
    });
  };

  const similarProperties = formData.filter((card) => 
  card.area === (listing?.area || null) && card.name !== (listing?.name || null)
);


  return (
    <section className="single-property-big-div">
      {loading && <p>Loading...</p>}
      {error && <p>Ka nje problem me shfaqjen e te dhenave...</p>}
      {listing && !loading && !error && (
        <div>
          <Navbar2 />
          <div className="bg-property"></div>
          <div className="single-property-big-div">
            <h2 className="single-property-title">
              {listing.shortdescription}
            </h2>
            <Swiper navigation>
              {listing.imageUrls.map((url, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={url}
                    alt={`Slide ${index}`}
                    style={{
                      height: "420px",
                      objectFit: "cover",
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <Container className="fluid">
              <Row className="g-0">
                <Col sm={12} md={12} lg={7}>
                  <div className="main-description">
                  <div className="share-links"> 
                    <p className="share-paragraph">Shpërndaj</p>
                   
                    <WhatsAppShareButton
                      propertyUrl={propertyUrl}
                      text={text}
                    />
                    <FacebookShareButton
                      propertyUrl={propertyUrl}
                      text={text}
                    />
                    </div>
                    <h4>Përshkrimi i detajuar</h4>
                    <p class="formatted-text">{listing.longdescription}</p>
                  </div>
                  <div className="other-information">
                    <h4>Informacione të tjera</h4>
                    <Row className="rresht g-0">
                      <Col
                        md={12}
                        lg={4}
                        className="justify-content-center"
                      >
                        <div className="icons-text">
                          <div className="single-icon">
                            <FaSearchengin />
                          </div>
                          <div className="single-property-listing">
                            <p className="first-property">Id:</p>
                            <p>{listing.name}</p>
                          </div>
                        </div>
                      </Col>
                      <Col
                        md={12}
                        lg={4}
                        className="justify-content-center"
                      >
                        <div className="icons-text">
                          <div className="single-icon">
                            <FaCity />
                          </div>
                          <div className="single-property-listing">
                            <p className="first-property">Tipologjia:</p>
                            <p>{listing.tipology}</p>
                          </div>
                        </div>
                      </Col>
                      <Col
                        md={12}
                        lg={4}
                        className="justify-content-center"
                      >
                        <div className="icons-text">
                          <div className="single-icon">
                            <IoKeySharp />
                          </div>
                          <div className="single-property-listing">
                            <p className="first-property">Statusi:</p>
                            <p>{listing.type}</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="rresht g-0">
                      <Col
                        md={12}
                        lg={4}
                        className="justify-content-center"
                      >
                        <div className="icons-text">
                          <div className="single-icon">
                            <SiGooglemaps />
                          </div>
                          <div className="single-property-listing">
                            <p className="first-property">Adresa:</p>
                            <p>
                              {listing.city},{listing.area}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col
                        md={12}
                        lg={4}
                        className="justify-content-center"
                      >
                        <div className="icons-text">
                          <div className="single-icon">
                            <HiSquare2Stack />
                          </div>
                          <div className="single-property-listing">
                            <p className="first-property">Sipërfaqja:</p>
                            <p>{listing.surface}</p>
                          </div>
                        </div>
                      </Col>
                      <Col
                        md={12}
                        lg={4}
                        className="justify-content-center"
                      >
                        <div className="icons-text">
                          <div className="single-icon">
                            <FaEuroSign />
                          </div>
                          <div className="single-property-listing">
                            <p className="first-property">Cmimi:</p>
                            <p>
                              {listing.type === "qira"
                                ? listing.coin === "Euro"
                                  ? `${listing.advertisedPrice} €/muaj`
                                  : `${listing.advertisedPrice} L/muaj`
                                : listing.coin === "Euro"
                                ? `${listing.advertisedPrice} €`
                                : `${listing.advertisedPrice} L`}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="rresht g-0">
                      <Col
                        md={12}
                        lg={4}
                        className="justify-content-center"
                      >
                        <div className="icons-text">
                          <div className="single-icon">
                            <FaBed />
                          </div>
                          <div className="single-property-listing">
                            <p className="first-property">Dhoma gjumi:</p>
                            <p>{listing.bedrooms}</p>
                          </div>
                        </div>
                      </Col>
                      <Col
                        md={12}
                        lg={4}
                        className="justify-content-center"
                      >
                        <div className="icons-text">
                          <div className="single-icon">
                            <FaBath />
                          </div>
                          <div className="single-property-listing">
                            <p className="first-property">Tualete:</p>
                            <p>{listing.bathrooms}</p>
                          </div>
                        </div>
                      </Col>
                      <Col
                        md={12}
                        lg={4}
                        className="justify-content-center"
                      >
                        <div className="icons-text">
                          <div className="single-icon">
                            <IoDocument />
                          </div>
                          <div className="single-property-listing">
                            <p className="first-property">Dokumentacioni:</p>
                            <p>{listing.documentation}</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {similarProperties.length > 0 && (
                  <div className="similar-properties">
                    <h4>Prona të ngjashme</h4>
                    <AliceCarousel
                      autoPlay
                      autoPlayInterval={3000}
                      disableDotsControls={true}
                      infinite
                      items={similarProperties
                        .map((card) => (
                          <Card
                            card={card}
                            title={card.name}
                            shortdescription={card.shortdescription}
                            imageUrl={card.imageUrls}
                            bathrooms={card.bathrooms}
                            bedrooms={card.bedrooms}
                            type={card.type}
                            surface={card.surface}
                            address={card.city}
                            price={card.advertisedPrice}
                            coin={card.coin}
                            area={card.area}
                          />
                        ))}
                    />
                  </div>
                  )}
                </Col>
                <Col sm={12} md={12} lg={5}>
                  <div className="agent-information">
                    <Row className="g-0">
                      <div className="single-property-agent">
                      {firstValidUrl ? (
                          <img src={firstValidUrl} className="agent-image" alt="Agent" />
                        ) : (
                          <img src={unknown} className="agent-image" alt="Agent" />
                        )}
                        <p className="agent-username">{agentData.username}</p>
                        <div className="agent-data">
                          <p>
                            <span className="agent-email">Email:</span>
                            <span>{agentData.email}</span>
                          </p>
                          <p>
                            <span className="agent-tel">Tel:</span>
                            <span>{agentData.tel}</span>
                          </p>
                        </div>
                      </div>
                      <form
                        className="form-wrapper-agent"
                        onSubmit={submitEmail}
                      >
                        <h2 className="contact-us-agent">Kontakto agjentin</h2>
                        <input
                          type="text"
                          name="name"
                          placeholder="Emri*"
                          onChange={handleStateChange}
                          value={mailerState.name}
                        ></input>
                        <input
                          type="text"
                          name="email"
                          placeholder="Email*"
                          onChange={handleStateChange}
                          value={mailerState.email}
                        ></input>
                        <textarea
                          type="text"
                          name="message"
                          placeholder="Shkruani mesazhin"
                          onChange={handleStateChange}
                          value={mailerState.message}
                        ></textarea>
                        <button className="hire-button-agent" type="submit">
                          DËRGO
                        </button>
                      </form>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
      <Footer />
      <Whatsapp propertyUrl={propertyUrl}/>
    </section>
  );
};

export default SingleProperty;
